<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}活動`"
    width="880px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="discount-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="name">
        <template #label>
          <span class="label">
            活動全名
            <span class="tips">顯示於明細(上限15字)</span>
          </span>
        </template>
        <el-input v-model="ruleForm.name" placeholder="請輸入活動全名" maxlength="15" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="short_name">
        <template #label>
          <span class="label">
            活動簡稱
            <span class="tips">顯示於POS按鈕(上限8字)</span>
          </span>
        </template>
        <el-input v-model="ruleForm.short_name" placeholder="請輸入活動簡稱" maxlength="8" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="object_to">
        <template #label>
          <span class="label">折扣類型</span>
        </template>
        <el-select v-model="ruleForm.object_to" placeholder="請選擇類型" style="width: 100%">
          <el-option v-for="item in object_to_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="time_period_type">
        <template #label>
          <span class="label">活動日期</span>
          <el-radio-group
            v-model="ruleForm.time_period_type"
            style="margin-left: 15px; transform: translateY(1px)"
            @change="clearItemValidate(ruleFormRef, 'time_period_type')"
          >
            <el-radio
              v-for="item in time_period_type_options"
              :key="item.label"
              :label="item.value"
              style="height: 22px"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-date-picker
          v-if="ruleForm.time_period_type === 2"
          v-model="datetimeRange"
          type="datetimerange"
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :default-time="defaultTime"
        />
      </el-form-item>
      <el-divider style="grid-area: 3/1/4/3" />
      <el-form-item class="form-item" style="grid-area: 4/1/5/2" prop="event_value">
        <template #label>
          <span class="label">內容</span>
          <el-radio-group v-model="ruleForm.event_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in event_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-input
          v-if="ruleForm.event_type === 1"
          v-model="ruleForm.event_value"
          placeholder="請輸入折數（個位數）"
          style="width: 100%"
          type="number"
        >
          <template #append>折</template>
        </el-input>
        <el-input
          v-if="ruleForm.event_type === 2"
          v-model="ruleForm.event_value"
          placeholder="請輸入折抵金額"
          style="width: 100%"
          type="number"
        >
          <template #prepend>－</template>
          <template #append>元</template>
        </el-input>
        <el-input
          v-if="ruleForm.event_type === 3"
          v-model="ruleForm.event_value"
          placeholder="請輸入改價金額"
          style="width: 100%"
          type="number"
        >
          <template #append>元</template>
        </el-input>
      </el-form-item>
      <el-form-item class="form-item" style="grid-area: 5/1/6/2" prop="available_shop_type">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.available_shop_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.available_shop_type === 2"
          v-model="ruleForm.available_shop_value_array"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.available_shop_type === 3"
          v-model:file_url="ruleForm.available_shop_file_url"
          v-model:file_name="ruleForm.available_shop_file_name"
        ></upload-file>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormRef)" style="margin-left: 25px">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, watch, reactive, defineAsyncComponent, getCurrentInstance } from 'vue';
import { formatDateTime } from '@/utils/datetime';
import { useActions } from '@/utils/mapStore';
import {
  object_to_options,
  time_period_type_options,
  event_type_options,
  available_shop_type_options,
  area_options,
} from '@/utils/define';
export default {
  name: 'manual-dialog',
  components: {
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    const datetimeRange = ref(null);
    //設定初始值
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        //日期格式
        if (ruleForm.value.time_period_type === 2) {
          datetimeRange.value = [];
          ruleForm.value.available_start_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_start_time);
          ruleForm.value.available_end_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_end_time);
          datetimeRange.value.push(ruleForm.value.available_start_time);
          datetimeRange.value.push(ruleForm.value.available_end_time);
        }
        //折數需要轉換成個位數(前端使用個位數、後端使用十位數)
        if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) / 10;
      } else
        ruleForm.value = {
          event_id: null,
          name: '',
          short_name: '',
          object_to: '',
          event_type: 1,
          event_value: '',
          time_period_type: 1,
          available_start_time: null,
          available_end_time: null,
          available_shop_type: 1,
          available_shop_value_array: [],
          available_shop_file_url: null,
          available_shop_file_name: null,
        };
    }

    //radio切換-清除錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    watch(
      () => [
        ruleForm.value.available_shop_type,
        ruleForm.value.available_shop_value_array,
        ruleForm.value.available_shop_file_url,
      ],
      () => {
        clearItemValidate(ruleFormRef.value, 'available_shop_type');
      }
    );
    watch(
      () => ruleForm.value.event_type,
      () => {
        if (ruleForm.value.event_type === cusProps.init?.event_type) {
          ruleForm.value.event_value = cusProps.init?.event_value;
          //折數需要轉換成個位數(前端使用個位數、後端使用十位數)
          if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) / 10;
        } else {
          ruleForm.value.event_value = '';
        }
        clearItemValidate(ruleFormRef.value, 'event_value');
      }
    );
    //日期相關
    watch(
      () => datetimeRange.value,
      () => {
        if (!datetimeRange.value) {
          ruleForm.value.available_start_time = null;
          ruleForm.value.available_end_time = null;
        } else if (datetimeRange.value.length > 0) {
          ruleForm.value.available_start_time = datetimeRange.value[0];
          ruleForm.value.available_end_time = datetimeRange.value[1];
        }
      }
    );
    const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 0)];

    //驗證
    const validateTime = (rule, value, callback) => {
      if (ruleForm.value.time_period_type === 1) callback();
      else {
        if (!datetimeRange.value) return callback(new Error('請選擇日期'));
        else callback();
      }
    };
    const validateShop = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.available_shop_value_array.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.available_shop_file_name || !ruleForm.value.available_shop_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const validateValue = (rule, value, callback) => {
      const regex = /^(\d{1})(\.\d{1})?$/;
      if (!value && value !== 0) return callback(new Error('不能為空值'));
      else if (Number(value) < 0) return callback(new Error('不能為負數'));
      else if (ruleForm.value.event_type === 1 && Number(value) >= 10) return callback(new Error('請輸入個位數'));
      else if (value === 1 && !regex.test(ruleForm.value.event_value)) return callback(new Error('小數點只能輸入一位'));
      else callback();
    };
    const rules = reactive({
      name: [{ required: true, message: '請輸入活動全名', trigger: 'blur' }],
      short_name: [{ required: true, message: '請輸入活動簡稱', trigger: 'blur' }],
      object_to: [{ required: true, message: '請選擇一個類型', trigger: 'blur' }],
      time_period_type: [{ validator: validateTime, trigger: 'blur' }],
      event_value: [{ validator: validateValue, trigger: 'blur' }],
      available_shop_type: [{ validator: validateShop, trigger: 'blur' }],
    });

    //送出
    const doSaveManualDiscount = useActions('manualDiscount', ['doSaveManualDiscount']);
    const handleSubmit = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        //折數需要轉換成十位數(前端使用個位數、後端使用十位數)
        if (ruleForm.value.event_type === 1) ruleForm.value.event_value = Number(ruleForm.value.event_value) * 10;
        await doSaveManualDiscount(ruleForm.value);
        proxy.$message({
          type: 'success',
          message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
        });
        submit({ action: cusProps.action, event: ruleForm.value });
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      datetimeRange,
      object_to_options,
      time_period_type_options,
      event_type_options,
      available_shop_type_options,
      area_options,
      defaultTime,
      rules,
      clearItemValidate,
      handleSubmit,
    };
  },
};
</script>
<style lang="scss" scoped>
.discount-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 45px 1fr 90px;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
.el-divider--horizontal {
  margin: 4px 0 24px;
}
</style>
